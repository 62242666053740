//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "news",

  data() {
    return {
      apiTitle: this.$api.api_title + 'images/',
      // 分页
      page_num: 1,
      page_size: 5,
      total: 0,
      topPicture: {},
      total_page: 0,
      article_list: [],
      module: '新闻中心',
      linguisticType: localStorage.getItem('linguisticType')
    };
  },

  created() {
    this.getImg();
    this.getList();
  },

  methods: {
    // 分页相关方法
    sizeChange(val) {
      this.page_size = val;
      this.getList();
    },

    pageChange(val) {
      this.page_num = val;
      this.getList();
    },

    getList() {
      this.$http.get(this.$api.listPressCenter, {
        params: {
          pageNum: this.page_num,
          pageSize: this.page_size,
          linguisticType: this.linguisticType
        }
      }).then(res => {
        if (res.code == 0) {
          res.data.rows.forEach(item => {
            item.releaseDate = item.releaseDate.substring(0, 10);
          });
          this.article_list = res.data.rows;
          this.total = res.data.total;
        }
      });
    },

    // 获取顶部图片
    getImg() {
      this.$http.get(this.$api.listTopPicture, {
        params: {
          pictureType: 5
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data.rows.length > 0) {
            this.topPicture = res.data.rows[0];
          }
        }
      });
    },

    // 首页/尾页
    goPage(page) {
      this.page_num = page;
      this.getList();
    },

    // 上一页/下一页
    changePage(type) {
      if (type == '-1' && this.page_num == 1) {
        return false;
      }

      if (type == '+1' && this.page_num == this.total_page) {
        return false;
      }

      this.page_num = type == '-1' ? this.page_num - 1 : this.page_num + 1;
      this.getList();
    },

    changeRoute(row, index) {
      console.log('传的值', row);
      this.$router.push({
        path: '/articleDetail',
        query: {
          path: '/news',
          params: JSON.stringify(row)
        }
      });
    }

  }
};