var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "top-img-box" }, [
        _c("img", {
          staticClass: "top-img",
          attrs: { src: _vm.apiTitle + _vm.topPicture.pictureAddress, alt: "" }
        })
      ]),
      _c("div", { staticClass: "subtitle" }, [
        _vm.linguisticType == 0
          ? _c("span", { staticClass: "span-subtitle" }, [_vm._v("新闻中心")])
          : _vm._e(),
        _vm.linguisticType == 1
          ? _c("span", { staticClass: "span-subtitle" }, [
              _vm._v("News Center")
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "nesContent" }, [
        _c(
          "div",
          { staticClass: "newsList" },
          _vm._l(_vm.article_list, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "newsItem",
                on: {
                  click: function($event) {
                    return _vm.changeRoute(item, index)
                  }
                }
              },
              [
                _c("div", { staticClass: "newsImg animated fadeInLeft" }, [
                  _c("img", {
                    attrs: { src: _vm.apiTitle + item.pictureAddress }
                  })
                ]),
                _c("div", { staticClass: "newsRight animated fadeInRight" }, [
                  _c("div", { staticClass: "rightTop" }, [
                    _c("div", { staticClass: "middleBox" }, [
                      _c("p", { staticClass: "newsTitle" }, [
                        _vm._v(_vm._s(item.headLine))
                      ]),
                      _c("p", [_vm._v(_vm._s(item.newsSummary))])
                    ])
                  ]),
                  _c("div", { staticClass: "rightBottom" }, [
                    _c("p", [_vm._v(_vm._s(item.releaseDate))]),
                    _c("div", { staticClass: "buttonBox" }, [
                      _vm.linguisticType == 0
                        ? _c("button", { staticClass: "moreButton" }, [
                            _vm._v("更多...")
                          ])
                        : _vm._e(),
                      _vm.linguisticType == 1
                        ? _c("button", { staticClass: "moreButton" }, [
                            _vm._v("More...")
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            )
          }),
          0
        )
      ]),
      _vm.total > 0
        ? _c(
            "sectionn",
            { staticClass: "page-box space-around" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "current-page": _vm.page_num,
                  "page-size": _vm.page_size,
                  total: _vm.total
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.pageChange
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }